import { defineStore } from "pinia";
import config from '@/config'
import { useCustomizerStore } from "~/stores/customizer";


export const useUserStore = defineStore({
    id: "user",
    state: () => ({
        user: null as any,
        selectedWorkspace: null as any,
        Customizer_drawer: config.Customizer_drawer,
        mini_sidebar: config.mini_sidebar,
        setHorizontalLayout: config.setHorizontalLayout, // Horizontal layout
        setRTLLayout: config.setRTLLayout, // RTL layout
        actTheme: config.actTheme,
        boxed: config.boxed,
        setBorderCard: config.setBorderCard
    }),

    getters: {
        getUser(): any {
            return this.user
        },
        getWorkspaces(): any {
            return this.user?.workspaces
        },
        getSelectedWorkspace(): any {
            return this.selectedWorkspace
        },
        getWorkspaceProducts(): any {
            return this.selectedWorkspace?.products
        }
    },
    actions: {
        async fetchUser() {
            const headers = useRequestHeaders(["cookie"]) as HeadersInit;
            const userData = await $fetch("/api/user", { headers });
            await this.setUser(userData)
        },
        async resetPassword() {
            const headers = useRequestHeaders(["cookie"]) as HeadersInit;
            const data: any = await $fetch("/api/reset-password", { headers });
            return data.ticket as string
        },
        updateUser(user: Record<string, any>) {
            this.user = { ...this.user, ...user }
        },
        updateSelectedWorkspace(workspace: Record<string, any>) {
            this.selectedWorkspace = { ...this.selectedWorkspace, ...workspace }
        },
        setWorkspace(payload: any) {
            this.selectedWorkspace = payload;
            const customizerStore = useCustomizerStore()
            customizerStore.setSidebarItemsViaProducts(payload.products)
        },
        async setUser(payload: any) {
            this.$state.user = payload
            if (this.user?.id && this.user?.workspaces) {
                this.setWorkspace(this.user?.workspaces[0] ?? {})
            }
        },
    },
    persist: true,
});
